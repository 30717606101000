
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainer view",
  components: {},
  data() {
    return {
      trainer: {},
      employeeInfo:{},
      empDistricts: [],
      load: false,
      id: this.$route.params.id,
      tabIndex: ref(0),
    };
  },
  async created() {
    this.load = true;
    await this.getEmployeeDetails();
  },
  methods: {
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));
    },
    async getEmployeeDetails() {
      this.load = true;
      await ApiService.get("configurations/employee/show/" + `${this.id}`)
        .then((response) => {
          this.load = false;
          this.employeeInfo = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
